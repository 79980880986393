/*
 * @Author: xin.song
 * @Date: 2020-03-12 17:09:05
 * @Last Modified by: xin.song
 * @Last Modified time: 2022-03-04 18:19:02
 */

import { service } from './service.js'
const api = {
	getWithdraw(req) {
		return service.post(`${process.env.VUE_APP_BASE_API}/oe/withdraw`, req)
	},
}

function stop() {
	var mo = function(e) {
		e.preventDefault()
	}
	document.body.style.overflow = 'hidden'
	document.addEventListener('touchmove', mo, false) //禁止页面滑动
}
/***取消滑动限制***/
function move() {
	var mo = function(e) {
		e.preventDefault()
	}
	document.body.style.overflow = '' //出现滚动条
	document.removeEventListener('touchmove', mo, false)
}

function download(apk,name) {
	// 创建隐藏的可下载链接
	var eleLink = document.createElement('a')
	eleLink.setAttribute('href', apk)
	// eleLink.setAttribute('download', name)
	eleLink.style.display = 'none'
	// 触发点击
	document.body.appendChild(eleLink)
	eleLink.click()
	// 然后移除
	document.body.removeChild(eleLink)
}

function GetQueryString(str) {
	var reg = new RegExp('(^|&)' + str + '=([^&]*)(&|$)', 'i')
	var r = window.location.search.substr(1).match(reg)
	if (r != null) return unescape(r[2])
	return null
}

function formatNum(num) {
	if (num) {
		return num.toString().replace(/\B(?=(?:\d{3})+\b)/g, ',')
	} else {
		return 0
	}
}

function debounce(fn, wait) {
	console.log('ababa', fn)

	//防抖
	var timeout = null
	return function() {
		if (timeout !== null) clearTimeout(timeout)
		timeout = setTimeout(fn, wait)
	}
}
function throttle(func, delay) {
	//节流
	var timer = null
	var startTime = Date.now() //设置开始时间
	return function() {
		var curTime = Date.now()
		var remaining = delay - (curTime - startTime) //剩余时间
		var context = this
		var args = arguments
		clearTimeout(timer)
		if (remaining <= 0) {
			// 第一次触发立即执行
			func.apply(context, args)
			startTime = Date.now()
		} else {
			timer = setTimeout(func, remaining) //取消当前计数器并计算新的remaining
		}
	}
}

function formatDate(oldDate, fmt) {
	//oldDate 13位
	//fmt為格式化 exp：yyyy-MM-dd hh:mm
	let date = new Date()
	if (typeof oldDate === 'string' || typeof oldDate === 'number') {
		date = new Date(+oldDate)
	} else {
		date = oldDate
	}
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
	}
	let o = {
		'M+': date.getMonth() + 1,
		'd+': date.getDate(),
		'h+': date.getHours(),
		'm+': date.getMinutes(),
		's+': date.getSeconds(),
	}
	function padLeftZero(str) {
		return ('00' + str).substr(str.length)
	}
	for (let k in o) {
		if (new RegExp(`(${k})`).test(fmt)) {
			let str = o[k] + ''
			fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
		}
	}
	return fmt
}

function day(sDate1, sDate2) {
	let sDate3 = new Date(sDate2).getTime() - new Date(sDate1).getTime() //時間差的毫秒數
	if (sDate3 < 0) {
		return 0
	}
	//計算出相差天數
	let iDays = Math.floor(sDate3 / (24 * 3600 * 1000))
	return iDays
}

function isJSON(str) {
	if (typeof str == 'string') {
		try {
			var obj = JSON.parse(str)
			if (typeof obj == 'object' && obj) {
				return true
			} else {
				return false
			}
		} catch (e) {
			console.log('error：' + str + '!!!' + e)
			return false
		}
	}
	console.log('It is not a string!')
}

function img_loading() {
	//初始化需要显示的图片，并且指定显示的位置
	var imglist = document.getElementsByClassName('loadimg')
	// console.log(imglist);
	for (var i = 0; i < imglist.length; i++) {
		let tt = imglist[i]
		// console.log(tt);
		// console.log(tt.loading);
		//防止重复加载
		if (tt.loading == true) {
			continue
		}
		//没有该属性代表不加载
		if (!tt.getAttribute('src-data')) {
			continue
		}
		tt.loading = true
		Imagess(tt.getAttribute('src-error') ? tt.getAttribute('src-error') : tt.src, tt.getAttribute('src-data'), (obj) => {
			tt.src = obj.src
			tt.removeAttribute('src-data')
		})
	}
}

function Imagess(errorimg, url, callback, error) {
	//判断是否加载完成
	var val = url
	var img = new Image()
	img.onload = () => {
		// window.hide();
		if (img.complete == true) {
			callback(img)
		}
	}
	//如果因为网络或图片的原因发生异常，则显示该图片
	if (error) {
		img.onerror = error
	} else {
		img.onerror = function() {
			img.src = errorimg
		}
	}
	img.src = val
}

// 暴露出这些属性和方法
export default {
	GetQueryString,
	formatNum,
	formatDate,
	isJSON,
	day,
	img_loading,
	Imagess,
	download,
	api,
	debounce,
	throttle,
	stop,
	move,
}
