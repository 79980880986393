<template>
  <div id="download" @click="download">
    <div class="downloa_apk" @click="download">
      DOWNLOAD <span v-if="num > 0">({{ num }}S)</span>
    </div>
  </div>
</template>

<script>
import '@/css/index.scss'
export default {
  data() {
    return {
      num: 3,
      apk: 'https://cdn.carzycoins.com/carzycoins-release.apk'
    }
  },
  mounted() {
    setTimeout(() => {
      clearInterval(this.timer); // 清除定时器
      this.download()
    }, 4000);
    this.$eventrack("open_carzycoins", "browse");

    // 设置每秒减少 num 的值的定时器
    this.timer = setInterval(() => {
      this.num--;
    }, 1000);
  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    clearInterval(this.timer);
  },
  methods: {
    download() {
      this.$eventrack("download_carzycoins", "");
      this.$global.download(this.apk);
    }
  }
}
</script>

<style>
</style>