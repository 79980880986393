import Vue from 'vue'
import App from './App.vue'
import global from '@/utlis/global.js'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
const firebaseConfig = {
  apiKey: "AIzaSyBQ0S21Y8XauI7Jh2pYKnbpZsnzJfuaGrg",
  authDomain: "cherryvideo-c4246.firebaseapp.com",
  projectId: "cherryvideo-c4246",
  storageBucket: "cherryvideo-c4246.appspot.com",
  messagingSenderId: "611566205959",
  appId: "1:611566205959:web:ba728ba53de60fd7d8bea2",
  measurementId: "G-E5GEDLXKRF"
};
import '@/css/include/hhrest.scss'
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
Vue.config.productionTip = false
Vue.prototype.$global = global
Vue.prototype.$api = global.api
Vue.prototype.$logEvent = (event, params = {}) => {
	logEvent(analytics, event, params)
}
Vue.prototype.$eventrack = (msg, method, map = {}) => {
	let params = {
		time: new Date(),
		message: msg,
		method: method,
		...map,
	}
	console.log(params);
	logEvent(analytics, msg, params)
}

new Vue({
  render: h => h(App),
}).$mount('#app')
